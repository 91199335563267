
import { Options, Vue } from 'vue-class-component'
import { Chart } from 'highcharts-vue'

@Options({
  components: {
    highcharts: Chart
  }
})
export default class SortingAlgorithms extends Vue {
  dataSet: number[] = [];
  dataSetSize = 30;
  speed = '2x';
  speedOptions = ['50x', '10x', '5x', '2x', '1x', '0.5x', '0.25x']
  chartType = true;

  operating = false;
  finished = false;

  // global index counter
  i = -1;

  // bubble sort
  noMatch = -1;

  // selection sort
  min = -1;
  max = -1;

  // insertion sort
  prev = -1;
  temp = 0;

  created () : void {
    this.randomiseData()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get chartOptions () : any {
    return {
      chart: {
        type: 'column',
        animation: false,
        backgroundColor: 'transparent'
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      title: {
        text: 'Visualisation using Highcharts'
      },
      tooltip: {
        enabled: false
      },
      xAxis: {
        categories: this.dataSet,
        title: {
          text: ''
        },
        labels: {
          enabled: true
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: false
        }
      },
      series: [{
        name: 'Data',
        data: this.dataSet,
        color: 'red'
      }]
    }
  }

  initialiseData () : void {
    this.dataSet = []
    for (let i = 1; i <= this.dataSetSize; i++) {
      this.dataSet.push(i)
    }
  }

  randomiseData () : void {
    this.initialiseData()
    let currentIndex = this.dataSetSize
    let randomIndex: number

    while (currentIndex !== 0) {
      // PICK RANDOM NUMBER & SWAP IT
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--;
      [this.dataSet[currentIndex], this.dataSet[randomIndex]] = [this.dataSet[randomIndex], this.dataSet[currentIndex]]
    }
  }

  async finish () : Promise<void> {
    let i = 0
    this.i = -1
    this.noMatch = -1
    this.min = -1
    this.max = -1
    this.prev = -1
    this.temp = 0

    do {
      this.finished = true
      await this.sleep(200, true)
      this.finished = false
      await this.sleep(100, true)
      i++
    } while (i < 3)

    this.operating = false
  }

  sleep (time = 250, excludeMultiplier = false): Promise<unknown> {
    var val = time
    if (!excludeMultiplier) {
      val = time / +this.speed.slice(0, this.speed.length - 1)
    }
    return new Promise((resolve) => setTimeout(resolve, val))
  }

  get labelName () : string {
    return !this.chartType ? 'Highcharts' : 'Custom'
  }

  getColour (index: number) : string {
    if (this.finished) {
      return 'green'
    } else if (index === this.noMatch) {
      return 'purple'
    } else if (index === this.min) {
      return 'purple'
    } else if (index === this.max) {
      return 'green'
    } else if (index === this.prev + 1 && this.prev !== this.i + 1 && this.i !== -1) {
      return 'green'
    } else if (index === this.prev && this.prev === this.i + 1) {
      return 'purple'
    } else if (index === this.i) {
      return 'red'
    }
    return 'black'
  }

  performInsertionSort () : void {
    this.randomiseData()
  }

  // -- Algorithms --------------------------
  async bubbleSortOptimised () : Promise<void> {
    const len = this.dataSet.length
    this.operating = true
    let checked = false
    let lastswap = len

    do {
      checked = false
      const limit = lastswap

      // This loop should be < not <=, but <= looks better with the colouring
      for (this.i = 0; this.i <= limit; this.i++) {
        if (this.dataSet[this.i] > this.dataSet[this.i + 1]) {
          await this.sleep()

          // swap
          const tmp = this.dataSet[this.i]
          this.dataSet[this.i] = this.dataSet[this.i + 1]
          this.dataSet[this.i + 1] = tmp

          // optimse solution ending iteration at last swap
          lastswap = this.i
          checked = true
        } else {
          this.noMatch = this.i
          await this.sleep(500)
          this.noMatch = this.dataSetSize
        }
      }
    } while (checked)

    await this.finish()
  }

  async bubbleSort () : Promise<void> {
    const len = this.dataSet.length
    this.operating = true
    let checked = false

    do {
      checked = false

      // Unoptimised, this will loop through the full loop everytime
      for (this.i = 0; this.i < len; this.i++) {
        if (this.dataSet[this.i] > this.dataSet[this.i + 1]) {
          await this.sleep()

          // swap
          const tmp = this.dataSet[this.i]
          this.dataSet[this.i] = this.dataSet[this.i + 1]
          this.dataSet[this.i + 1] = tmp

          checked = true
        } else {
          this.noMatch = this.i
          await this.sleep(500)
          this.noMatch = this.dataSetSize
        }
      }
    } while (checked)

    await this.finish()
  }

  async selectionSort () : Promise<void> {
    const len = this.dataSet.length
    this.operating = true

    for (let j = 0; j < len - 1; j++) {
      this.min = j
      for (this.i = j + 1; this.i < len; this.i++) {
        await this.sleep()
        if (this.dataSet[this.i] < this.dataSet[this.min]) {
          this.min = this.i
        }
      }

      const tmp = this.dataSet[j]
      this.dataSet[j] = this.dataSet[this.min]
      this.dataSet[this.min] = tmp
    }

    await this.finish()
  }

  async selectionSortOptimised () : Promise<void> {
    const len = this.dataSet.length
    this.operating = true

    for (let j = 0, k = len - 1; j < k; j++, k--) {
      this.min = j // min
      this.max = j
      for (this.i = j; this.i <= k; this.i++) {
        await this.sleep()
        if (this.dataSet[this.i] < this.dataSet[this.min]) {
          this.min = this.i
        } else if (this.dataSet[this.i] > this.dataSet[this.max]) {
          this.max = this.i
        }
      }

      const tmp = this.dataSet[j]
      this.dataSet[j] = this.dataSet[this.min]
      this.dataSet[this.min] = tmp

      // if the max was at the start,
      // it has now swapped to where the min was
      if (j === this.max) {
        this.max = this.min
      }
      const tmpMax = this.dataSet[k]
      this.dataSet[k] = this.dataSet[this.max]
      this.dataSet[this.max] = tmpMax
    }

    await this.finish()
  }

  async insertionSort () : Promise<void> {
    const len = this.dataSet.length
    this.operating = true

    for (let h = 1; h < len; h++) {
      const current = this.dataSet[h]
      this.temp = current // global temp to display as its more eye pleasing
      this.i = h - 1

      while ((this.i > -1) && (current < this.dataSet[this.i])) {
        this.dataSet[this.i + 1] = this.dataSet[this.i]
        this.i--
        await this.sleep()
        this.prev = this.i
      }

      this.dataSet[this.i + 1] = current
    }

    await this.finish()
  }
}
